<template>
  <div class="room-body h-100_">
    <room-body-header />
    <div class="room-body-content w-100_">
      <common-table short ref="table" :data="tableList" @selectionChange="handleSelectionChange"
        :setQuerySearchPagesize="setQuerySearchPagesize" :setQuerySearchPageNum="setQuerySearchPageNum"
        :querySearch="querySearch" :queryTableData="getTableData" :total="total" :style="'width: 100%;'">
        <el-table-column type="selection" v-if="!isMM" width="55" align="center">
        </el-table-column>
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            {{
              (querySearch.pageNum - 1) * querySearch.pageSize +
              1 +
              scope.$index
            }}
          </template>
        </el-table-column>
        <!-- 
        <el-table-column
          align="left"
          prop="buildingName"
          width="100"
          label="楼栋"
        >
        </el-table-column>
        <el-table-column
          align="left"
          v-if="cellsData"
          prop="floorNum"
          width="100"
          label="楼层数量"
        >
        </el-table-column> -->

        <!-- <el-table-column
          v-if="!cellsData"
          align="left"
          prop="buildingName"
          width="100"
          label="楼栋名称"
        >
        </el-table-column> -->
        <el-table-column align="left" v-if="!cellsData" prop="roomNo" width="120" label="房间号"><template
            slot-scope="scope">
            {{ scope.row.roomNo }}
          </template>
        </el-table-column>
        <el-table-column align="left" v-if="!cellsData" prop="buildingCharacter" label="男女属性" width="100">
          <template slot-scope="scope">
            {{ ["男生宿舍", "女生宿舍"][scope.row.roomCharacter] || "待指定" }}
          </template>
        </el-table-column>
        <el-table-column align="left" v-if="!cellsData" prop="lock" width="180" label="入住/可住">
          <template slot="header">
            <svg @click="refresh" class="icon"
              style="width: 1em;height: 1em;cursor: pointer; vertical-align: middle;fill: currentColor;overflow: hidden;"
              viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1794">
              <path
                d="M938.336973 255.26894c-16.685369-6.020494-35.090879 2.752226-40.939358 19.437594l-24.770032 69.493701c-29.070385-65.537376-74.998152-123.162103-133.48295-166.337645-185.947253-137.611288-450.848984-100.112212-590.180413 83.942886C81.534688 350.908785 52.980346 460.653788 68.805644 570.742819c15.825298 110.605073 74.48211 208.481102 164.789518 275.394591 75.686209 55.904586 164.273476 83.082815 252.172686 83.082815 128.494541 0 255.26894-57.624727 338.007727-166.853687 36.639006-48.335965 61.581052-102.348396 74.48211-160.833193 3.78431-17.373425-7.224593-34.402822-24.426004-38.187133-17.201411-3.78431-34.402822 7.052579-38.187133 24.426004-10.836889 49.36805-31.994625 95.123803-62.957164 135.891147-118.173694 156.016798-342.996136 187.839409-500.90509 70.869814-76.546279-56.592642-126.086343-139.33143-139.503444-232.907106-13.417101-93.059634 10.664875-185.775239 67.77356-261.11742C318.05409 144.491853 542.704519 112.497228 700.785486 229.466823c57.280699 42.315471 100.112212 100.972283 123.334117 167.197715l-110.261045-43.003528c-16.513355-6.364522-35.090879 1.720141-41.627415 18.233496-6.536536 16.513355 1.720141 35.090879 18.233496 41.627415l162.38132 63.473207c3.78431 1.548127 7.740635 2.236183 11.69696 2.236183 0.516042 0 1.032085-0.172014 1.548127-0.172014 1.204099 0.172014 2.408198 0.688056 3.612296 0.688056 13.245087 0 25.630102-8.256677 30.274483-21.32975l57.796741-161.693264C963.623047 279.694944 955.022342 261.289434 938.336973 255.26894z"
                fill="#575B66" p-id="1795"></path>
            </svg> 入住/可住
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.livedInNum || 0 }}/{{ scope.row.bedNum || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" v-if="!cellsData" prop="lockBindingFlag" width="80" label="门锁状态">
          <template slot-scope="scope">
            <span v-if="scope.row.lockBindingFlag == 1">已绑定</span>
            <el-popover placement="top-start" trigger="hover" content="无法使用，请通过洛克侠app进行绑定">
              <div>无法使用，请通过洛克侠app进行绑定</div>
              <span slot="reference" v-if="scope.row.lockBindingFlag != 1">未绑定</span>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column align="left" v-if="!cellsData" prop="existStudents" label="学生列表">
          <template slot-scope="scope">
            <div v-if="scope.row.existStudents">
              <span v-for="item in scope.row.existStudents
                .split(',')
                .filter(Boolean)" :key="item" style="margin-right: 5px">
                <a class="c-p" style="color: #409eff; text-decoration: none" @click="showStudent(item.split('-')[0])">{{
                  item.split("-")[1] }}
                </a>
                ({{ item.split("-")[2] }})
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMM" fixed="right" align="left" width="100" prop="address" label="操作">
          <template slot-scope="scope">
            <div class="d-f ai-c jc-sa set">
              <img title="添加学生" style="width: 16px" class="c-p el-icon-edit-outline" src="@/assets/plus.svg"
                @click="plusStudent(scope.row)" />
              <img title="修改" class="c-p el-icon-edit-outline" src="@/assets/edit.svg" @click="edit(scope.row)" />
              <img title="删除" src="@/assets/delete.svg" class="el-icon-delete c-p" @click="deleteF(scope.row)" />
            </div>
          </template>
        </el-table-column>
      </common-table>
    </div>
    <room-body-add-room @query="getRoomData"  :visible.sync="showEdit" @submit="finishEdit" title="编辑" :data="roomEditData" />
    <cell-set :deleteVisible.sync="deleteVisible" :modifyName="modifyName" @deleteSure="deleteFunc" type="room" />
    <room-body-add-cell @submit="modify" :visible.sync="modifyVisible" :data="modifyData"></room-body-add-cell>
    <el-dialog :visible.sync="studentVisible" width="900px" title="学生信息">
      <div slot="title">
        <b>学生信息</b> <el-button style="margin-left:5px" type="text" @click="editStudent">编辑学生信息</el-button>
      </div>
      <div class="d-f jc-sb" style="flex-wrap: wrap">
        <div style="width: 33%; margin: 10px 0" v-for="item in studentInfoList" :key="item.id" class="d-f">
          <div style="
                        width: 100px;
                        font-weight: bold;
                        text-align: right;
                        padding-right: 10px;
                      ">
            {{ item.label }}:
          </div>
          <div style="width: 190px">
            {{
              item.function ? item.function(studentInfo) : studentInfo[item.id]
            }}
          </div>
        </div>
      </div>
    </el-dialog>
    <room-add-student :roomSex="ssex" :ssex="ssex" ref="addStudent" @addStudents="addStudents"></room-add-student>
    <hr />
    <student-body-add-student :visible.sync="showStudentEdit" @submit="finishStudentEdit" title="编辑学生信息"
      :data="studentInfo" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("room");
const { mapActions: setActions, mapState: setDState } =
  createNamespacedHelpers("setting");
const { mapActions: studentMapAction, mapMutations: studentMapMutation } =
  createNamespacedHelpers("student");
const { mapActions: studentAction } = createNamespacedHelpers("student");
import RoomBodyHeader from "./roomBodyHeader.vue";
import RoomBodyAddRoom from "./roomBodyAddRoom.vue";
import RoomBodyAddCell from "./roomBodyAddCell.vue";
import StudentBodyAddStudent from "../student/studentBodyAddStudent.vue";
import md5 from "../common/md5";
import CellSet from "./cellSet.vue";
import { getPassWord } from "../common/store";
import RoomAddStudent from "./roomAddStudent.vue";
export default {
  name: "room-body",
  components: {
    RoomBodyHeader,
    RoomBodyAddRoom,
    CellSet,
    RoomBodyAddCell,
    RoomAddStudent,
    StudentBodyAddStudent
  },
  data() {
    return {
      ssex: "",
      addStudentRoomItem: {},
      studentVisible: false,
      showEdit: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
      showStudentEdit: false,
      modifyRowId: null,
      deleteRowId: null,
      roomEditData: {},
      tableHeight: null,
      modifyData: {},
      editData: {},
      needConfirmDelete: false,
      studentInfo: {},
      studentInfoList: [
        {
          id: "studentName",
          label: "学生姓名",
        },
        {
          id: "ssex",
          label: "性别",
          function: (item) => (item.ssex == 1 ? "女" : "男"),
        },
        {
          id: "enrolYear",
          label: "入学年度",
        },
        {
          id: "phone",
          label: "手机号码",
        },
        {
          id: "identityNo",
          label: "身份证号码",
        },
        {
          id: "xx",
          label: "院系",
          function: (item) =>
            (item.academyName || "") +
            (item.departmentName ? "/" + item.departmentName : ""),
        },
        {
          id: "buildingName",
          label: "楼栋",
        },
        {
          id: "roomNo",
          label: "房号",
        },
        {
          id: "yy",
          label: "学历",
          function: (item) =>
            ["专科", "本科", "硕士", "博士", "无"][item.degreeProperties],
        },
      ],
    };
  },
  methods: {
    ...studentMapAction(['getBuildInfo', 'editStudentData', "getAdacyList",
      "getOrgList",]),
    ...mapActions([
      "getBuildData",
      "modifyBuild",
      "modifyRoomData",
      "deleteBuild",
      "deleteRoomData",
      "getRoomData",
      "getDtatisticalInformation",
      "refreshRome"
    ]),
    ...mapMutations([
      "setQuerySearch",
      "setChooseType",
      "setSectionItem",
      "setQuerySearchPagesize",
      "setQuerySearchPageNum",
      "setTableList",
      "setRoomSex",
    ]),

    ...setActions(["getAllSettingMessage"]),
    ...studentAction(["getStudentInfo"]),
    handleSelectionChange(val) {
      // 多选的item
      this.setSectionItem(val);
    },
    editStudent() {
      this.studentVisible = false;
      this.showStudentEdit = true
    },
    refresh() {
      this.refreshRome()
    },
    finishStudentEdit(data) {
      //  编辑完成获取数据；
      this.editStudentData(data).then(() => {
        this.showStudentEdit = false;
        this.refresh()
      });
    },
    addStudents(batStudent) {
      const l = batStudent.length;
      if (l) {
        if (
          this.addStudentRoomItem.bedNum <
          l + this.addStudentRoomItem.livedInNum
        ) {
          this.$message.error("学生数量不应该大于床位数量");
          return;
        }
        const existIds = this.addStudentRoomItem.existStudents
          .split(",")
          .filter(Boolean)
          .map((it) => it.split("-")[0]);
        const params = {
          ...this.addStudentRoomItem,
          delStudentIds: "",
          addStudentIds: [
            // ...
            ...batStudent.map((it) => it.id).filter(it => !existIds.includes(String(it))),
          ].join(","),
        };
        this.modifyRoomData(params).then((res) => {
          console.log(res);
        });
      }
    },
    plusStudent(item) {
      this.addStudentRoomItem = item;
      this.ssex = item.roomCharacter || "";
      this.$refs.addStudent.show();
    },
    showStudent(id) {
      this.getStudentInfo(id).then((res) => {
        console.log(res)
        this.studentInfo = res;
        console.log(this.$store)
        this.$store.commit('student/setTableList', [res])
        this.$router.push('/student?hadData=true')
        // this.studentVisible = true;
      });
    },
    deleteFunc() {
      //   删除
      if (this.chooseType == "cell") {
        this.deleteBuild(this.deleteRowId).then(() => {
          this.deleteVisible = false;
        });
      } else {
        this.deleteRoomData(this.deleteRowId).then(() => {
          this.deleteVisible = false;
        });
      }
    },
    modify(val) {
      //  修改
      if (this.chooseType == "cell") {
        this.modifyBuild({
          id: this.modifyRowId,
          ...val,
        }).then(() => {
          this.modifyVisible = false;
        });
      }
    },
    finishEdit(data) {
      //  编辑完成获取数据；
      console.log("data", data);

      const params = {
        ...data,
        addStudentIds: data.student.map((it) => it.id).join(","),
      };
      this.modifyRoomData(params).then(() => {
        this.showEdit = false;
      });
    },
    edit(row) {
      this.setRoomSex(row.buildingCharacter || "");
      // 编辑当前数据
      if (this.needConfirmDelete) {
        this.confirmFunc().then(() => {
          if (this.chooseType == "cell") {
            this.modifyData = { ...row };
            this.modifyVisible = true;
            this.modifyRowId = row.id;
          } else {
            this.roomEditData = {
              ...row,
              roomNo: row.roomNo,
              bedNum: row.bedNum, // 床位
              student: row.existStudents || [],
            };
            this.showEdit = true;
          }
        });
      } else {
        if (this.chooseType == "cell") {
          //
          this.modifyData = { ...row };
          this.modifyVisible = true;
          this.modifyRowId = row.id;
        } else {
          this.roomEditData = {
            ...row,
            roomNo: row.roomNo,
            bedNum: row.bedNum, // 床位
            student: row.existStudents || [],
          };
          this.showEdit = true;
        }
      }
    },
    confirmFunc() {
      return new Promise((res) => {
        this.$prompt("请输入登录密码进行验证！", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "password",
        }).then(({ value }) => {
          if (md5(value) == getPassWord()) {
            res();
          } else {
            this.$message.error("密码错误！");
          }
        });
      });
    },
    deleteF(row) {
      // 删除当前数据
      // if (this.chooseType == "cell") {
      //
      if (this.needConfirmDelete) {
        this.confirmFunc().then(() => {
          this.deleteRowId = row.id;
          this.deleteVisible = true;
        });
      } else {
        this.deleteRowId = row.id;
        this.deleteVisible = true;
      }

      // } else {
      //
      // this.$message.error("无法删除，请通过洛克侠app 绑定");
      //   this.$confirm("你确定要删除当前房间信息吗？", "房间删除确认", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }).then(() => {
      //     this.deleteRoomData(row.id);
      //   });
      // }
    },
    getTableData(params) {
      const roomId = this.$route.query.roomId
      const pa = { ...params, ...(roomId ? { id:roomId } : {}) }
      if (this.chooseType == "cell") {
        this.getBuildData(pa);
      } else {
        this.getRoomData(pa);
      }
    },
    rerender() {
      this.$refs.table.doLayout();
    },
  },
  mounted() {
    this.setTableList([]);
    this.setSectionItem(null),
      this.getDtatisticalInformation();
    this.setChooseType("room");
    this.getAllSettingMessage();
    console.log(this.$store);
    this.getBuildInfo();
    this.getAdacyList();
    this.getOrgList()
  },
  beforeMount() { },
  computed: {
    ...mapState(["tableList", "chooseType", "total", "querySearch"]),
    ...setDState(["settingBaseinfo"]),
    cellsData() {
      return this.chooseType == "cell";
    },
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
  watch: {
    settingBaseinfo(val) {
      val.forEach((item) => {
        if (item.paramTypeCode == "securitySet") {
          this.needConfirmDelete = item.paramValue == 1;
        }
      });
    },
    modifyVisible(val) {
      !val && (this.modifyName = "");
    },
    tableList(val) {
      this.rerender();
    },
  },
};
</script>

<style lang="scss" >
.room-body {
  margin-left: 24px;

  .room-body-content {
    background: #fff;
    height: calc(100% - 85px);
    margin-top: 24px;
    border: 1px solid #d7d8d9;

    .set {
      border-left: 1px solid #e9e9e9;

      i {
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}
</style>
