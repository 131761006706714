<template>
  <div>
    <div class="room-body-header d-f ai-c jc-sb">
      <div class="d-f ai-c jc-sb">
        <el-input :placeholder="placeholder" v-model="searchQuery" clearable style="width: 200px; margin-right: 8px"
          @clear="search(false)" />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <common-daoru v-if="!isMM" title="批量导入" @click="importVisible = true" type="text" class="icon-btn-box"
          style="margin-left: 20px">
        </common-daoru>
        <common-daochu v-if="!isMM" title="全部导出" @click="exportData(true)" type="text" class="icon-btn-box"
          style="margin: 0 16px">
          <img style="" class="c-p icon-btn icon-btn-all" src="@/assets/daochuquanbu.svg" alt="" srcset="" />
        </common-daochu>
      </div>
      <div>
        <span style="font-size: 13px; margin-right: 10px" class="c-6 statisticalInformation">
          <b>房间数</b>
          <span>{{ statisticalInformation.roomTotalNum }}</span>
          <span>(男</span>
          <span>{{ statisticalInformation.boyRoomNum }}</span>
          <span>/女</span>
          <span>{{ statisticalInformation.girlRoomNum }});</span>
          <b> 床位数</b>
          <span>{{
            +statisticalInformation.boyRoomBedNums +
            +statisticalInformation.girlRoomBedNums
          }}
        </span>
        <span>(男</span>
        <span>{{ statisticalInformation.boyRoomBedNums }}</span>
        <span>/女</span>
        <span>{{ statisticalInformation.girlRoomBedNums }});</span>
        <b> 空床位</b>
        <span>{{
          +statisticalInformation.boyRoomBedNums +
          +statisticalInformation.girlRoomBedNums -
          statisticalInformation.boyRoomLivedInNums -
          statisticalInformation.girlRoomLivedInNums
        }}</span>
        <span>(男</span>
        <span>{{
          +statisticalInformation.boyRoomBedNums -
          statisticalInformation.boyRoomLivedInNums
        }}</span>
        <span>/女</span>
        <span>{{
          statisticalInformation.girlRoomBedNums -
          statisticalInformation.girlRoomLivedInNums
        }}</span>
        <span>)</span>
        </span>
        <!-- <el-button v-if="checkedRoom && !isMM" @click="drawer = true">添加房间</el-button> -->
        <!-- <el-button v-if="!isMM" @click="visible = true" type="primaryHover">批量添加楼栋</el-button> -->
        <!-- <el-button v-if="!isMM" @click="exportAllRoom" type="primary"
          >全部房间导出</el-button
        > -->
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" custom-class="room-header-add" title="批量添加" :visible.sync="visible"
      width="360px">
      <el-input placeholder="请输入楼栋，多个用“/”隔开，如1号楼/2号楼/3号楼" v-model="batchAdd.buildingNames" />
      <el-input type="number" placeholder="请输入楼层数量" :value="batchAdd.floorNum" min="0"
        @input="(val) => (batchAdd.floorNum = Math.abs(val) >>> 0)" />
      <el-input placeholder="请输入房间数量，例：1-10，15-20（共16间房）" v-model="batchAdd.roomNoInfo" />
      <el-input placeholder="请输入房间床位数量" v-model="batchAdd.bedNum" />
      <el-select style="width: 100%" v-model="batchAdd.roomCharacter">
        <el-option label="男生宿舍" value="0">男生宿舍</el-option>
        <el-option label="女生宿舍" value="1">女生宿舍</el-option>
        <el-option label="男女混住" value="2">男女混住</el-option>
      </el-select>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
    <room-body-add-room :visible.sync="drawer" @submit="addRoomFunc" />
    <el-dialog :close-on-click-modal="false" custom-class="room-header-add-a" title="批量导入" :visible.sync="importVisible"
      width="360px" style="padding: 0">
      <el-alert v-if="filesName" :title="filesName" type="success" effect="dark" @close="filesName = ''"
        custom-class="dialog-import">
      </el-alert>
      <div v-else class="d-f ai-c" style="flex-direction: column">
        <el-button @click="downloadTemplate" type="text" style="width: auto">点击下载房间信息模板</el-button>

        <el-button style="margin: 5px auto" @click="$refs.file.click()"><img style="width: 10px" src="@/assets/upload.png"
            alt="" />
          上传文件</el-button>
        <input @change="fileChange" ref="file" type="file" style="display: block; width: 1px; height: 0; opacity: 0"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

        <div slot="tip" class="f-12 c-9 ta-c">支持扩展名：xlsx</div>
      </div>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click="addsure">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="errorMsgVisible" width="1500px" custom-class="other-dialog"
      top="147px">
      <div slot="title" class="d-f ai-c">
        <img src="@/assets/warn.svg" style="margin-right: 8px" />
        <span>{{
          "正确导入" +
          (errorMsg.total - errorMsg.errorNumber) +
          "条，错误信息" +
          errorMsg.errorNumber +
          "条"
        }}
        </span>
      </div>
      <el-table :data="errTableList" style="width: 100%">
        <el-table-column type="index" label="序号" width="100" align="center">
        </el-table-column>
        <el-table-column v-for="item in errorCloumn" :key="item.prop" :prop="item.prop" :min-width="item.width"
          :label="item.label" :show-overflow-tooltip="!(editErr && item.prop != 'reason')">
          <template slot-scope="scope">
            <el-input v-if="editErr && item.prop != 'reason'" size="mini" v-model="scope.row[item.prop]" />
            <span v-else :style="item.prop == 'reason' ? 'color:red' : ''">{{
              scope.row[item.prop]
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="errorMsgVisible = false">取消</el-button>
        <el-button v-if="!editErr" @click="editErr = !editErr">修改错误信息</el-button>
        <el-button v-else @click="editErrFunc">确认修改并导入</el-button>

        <el-button type="primary" @click="exportErrMessage">保存错误信息</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("room");
import RoomBodyAddRoom from "./roomBodyAddRoom.vue";
import { getPassWord, getSchoolId } from "../common/store";
export default {
  name: "room-body-header",
  components: {
    RoomBodyAddRoom,
  },
  data() {
    return {
      T: null,
      searchQuery: "",
      visible: false,
      drawer: false,
      placeholder: "请输入楼栋",
      batchAdd: {
        buildingNames: "", // 楼栋
        floorNum: "", //  楼层数量
        roomNoInfo: "", // 房间
        bedNum: "", //  床位
        roomCharacter: "0",
      },
      filesName: "",
      File: null,
      importVisible: false,
      errorMsgVisible: false,
      errorMsg: {
        total: 32,
        errorNumber: 2,
      },
      errTableList: [],
      uuid: null,
      errorCloumn: [
        { prop: "studentName", label: "姓名", width: "80" },
        // { prop: "studentNo", label: "学号", width: "80" },
        { prop: "identityNo", label: "身份证", width: "150" },
        // { prop: "phone", label: "手机号码", width: "120" },
        { prop: "enrolYear", label: "入学年度", width: "100" },
        { prop: "academyName", label: "学院", width: "100" },
        { prop: "departmentName", label: "系", width: "100" },
        { prop: "buildingName", label: "楼栋", width: "80" },
        { prop: "roomNo", label: "房号", width: "80" },
        { prop: "reason", label: "错误原因", width: "300" },
      ],
      editErr: false, //  是否修改错误信息
    };
  },
  methods: {
    ...mapActions([
      "searchTable",
      "addRoomData",
      "batAdd",
      "saveRoomInfo",
      "getBuildData",
      "getRoomData",
      "getAllRoomExcel",
      "getErrRoomExcel",
      "uploadRoomExcelTemplate",
      "batAddStudents",
      "getRoomExcelTemplate",
      "getRoomExcel",
    ]),
    ...mapMutations(["setSearchValue"]),
    fileChange(e) {
      const file = e.target.files[0];
      this.filesName = file.name;
      this.File = file;
    },
    downloadTemplate() {
      this.getRoomExcelTemplate();
    },
    exportErrMessage() {
      if (this.uuid) {
        this.getErrStudentExcel({ uuid: this.uuid });
      }
    },
    editErrFunc() {
      this.batAddStudents(this.errTableList).then((res) => {
        if (res.length) {
          this.errTableList = res;
          this.editErr = !this.editErr;
        } else {
          this.$message.success("批量修改成功！");
          this.errTableList = [];
          this.errorMsgVisible = false;
        }
      });
    },
    addsure() {
      // 批量添加确认
      const fd = new FormData();
      fd.append("file", this.File);
      fd.append("id", getSchoolId());
      this.uploadRoomExcelTemplate(fd)
        .then(() => {
          // this.errorMsgVisible = true;
        })
        .catch((err) => {
          console.log(err);
          const result = err.data.data;
          this.uuid = result.uuid;
          this.errorMsg = {
            total: result.listExcels.length,
            errorNumber: Object.keys(result.errorMap).length,
          };
          const list = result.listExcels.map((it) => ({ ...it, reason: [] }));
          Object.values(result.errorMap).forEach((yy) => {
            yy.forEach((r) => {
              const item = list[r.row];
              item.reason.push(r.errorMsg);
            });
          });

          this.errTableList = list
            .filter((it) => it.reason && it.reason.length)
            .map((it) => ({
              ...it,
              reason: it.reason.join(","),
            }));
          this.errorMsgVisible = true;
        });
      this.importVisible = false;
    },
    exportData(all = false) {
      this.getRoomExcel(
        all
          ? 0
          : {
            exportIds: this.sectionItem.map((it) => it.id).join(","),
          }
      );
    },
    search(f = true) {
      if (this.searchQuery || !f) {
        this.setSearchValue(this.searchQuery);
        if (this.chooseType == "cell") {
          this.getBuildData({ pageNum: 1, buildingId: "" });
        } else {
          this.getRoomData({ pageNum: 1, buildingId: "", floorNo: "" });
        }
      }
    },
    sure() {
      // 批量添加确认
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        const { buildingNames, floorNum, roomNoInfo, bedNum } = this.batchAdd;
        if (buildingNames && floorNum && roomNoInfo && bedNum) {
          this.batAdd(this.batchAdd).then(() => {
            this.visible = false;
          });
        } else {
          this.$message.error("信息请填写完整");
        }
      }, 200);
    },

    addRoomFunc(data) {
      //  添加房间信息
      console.log(data);
      const params = {
        ...data,
        roomNo: data.roomNo,
        bedNum: data.bedNum,
        addStudentIds: (data.student || []).map((it) => it.id).join(","),
      };
      this.addRoomData(params).then(() => {
        this.drawer = false;
      });
    },
    exportAllRoom() {
      clearTimeout(this.T);
      this.T = setTimeout(() => {
        this.getAllRoomExcel();
      }, 500);
    },
  },
  watch: {
    visible() {
      this.batchAdd = {
        buildingNames: "", // 楼栋
        floorNum: "", //  楼层数量
        roomNoInfo: "", // 房间
        roomCharacter: "0",
      };
    },
    searchValue(val) {
      this.searchQuery = val;
    },
    chooseType(val) {
      if (val == "cell") {
        this.placeholder = "请输入楼栋";
      } else {
        this.placeholder = "请输入房间号";
      }
    },
    importVisible() {
      this.filesName = "";
    },
    errorMsgVisible(val) {
      if (!val) {
        this.editErr = false;
      } else {
        this.getStudentData();
      }
    },
  },
  computed: {
    ...mapState([
      "chooseType",
      "searchValue",
      "checkedRoom",
      "statisticalInformation",
    ]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
  border: 1px solid #d7d8d9;
}

.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }

  button {
    margin: 0 4px;
    width: 88px;
  }
}

.statisticalInformation {
  b {
    margin: 0 5px;
  }
}
</style>